.settings .homeLogoContainer,
.settings.homeLogoContainer {
    background-position-x: 16px;
    background-position-y: 16px;
    background-size: 70px;
    padding-top: 0;
    height: 100px;
}

.settings .startTitle {
    text-align: left;
    margin-left: 100px;
}

.settings-page {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: calc(100dvh - 64px);
    justify-content: space-between;

    @media only screen and (min-width: 960px) {
        margin: 0 auto;
        max-width: 460px;
    }

    @media only screen and (max-height: 600px) {
        padding-bottom: calc(2 * $sg-padding);
    }
    
    .settings-panel {
        flex-shrink: 0;
    }
}

.settings-heading {
    font-size: 13px;
    color: $sg-white;
    font-weight: 600;
    height: 20px;
    align-items: end;
    min-height: 36px;
}

.settings.outerContainer {
    width: 100%;
    height: calc(100% + 64px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $sg-overlay-green;
    // overflow-y: hidden;
    position: fixed;
    top: 0;
    left: 0;
}

.settings.innerContainer {
    width: 80%;
    background-color: $sg-green;
    border-radius: $sg-border-radius-large;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-width: 350px;
    position: absolute;
    max-width: 460px;
    height: calc(96vh - 70px);
    overflow-y: auto;
    top: $sg-padding;
    padding-bottom: $sg-padding;

    @media only screen and (max-height: 600px) {
        padding-bottom: calc(3 * $sg-padding);
    }
}

.consent-info {
    font-size: 13px;
    padding: 0 $sg-padding;

    &.data-privacy {
        padding: 8px $sg-padding 0;
        display: flex;
        margin: 20px 0;
        row-gap: 16px;
        justify-content: space-between;
        width: 100%;

        a {
            color: $sg-black;
            font-weight: bolder;
        }

        @media only screen and (min-width: 960px) {
            margin-top: 40px;
            row-gap: 24px;
        }
    }
}

.preference-item {
    margin-bottom: 0 !important;
    margin-top: 6px !important;
}


.segment {
    background: $sg-green;
    color: $sg-black;
    border: 2px solid $sg-white;
    border-radius: 8px;
    column-gap: 1px;

    label,
    &-button {
        background-color: $sg-green;
        color: $sg-white;
        transition: background-color 0.1s ease, color 0.1s ease;
    }

    .segment-button-has-label {
        background: $sg-green;
        color: $sg-white;
    }

    .segment-button {
        margin: 0;
        
        button {
            margin: 0;
            padding: 0;
        }

        label {
            background-color: transparent;
            color: $sg-white;
        }

        .segment-button-indicator {
            opacity: 0;
            padding: 0;
        }

        &-checked {
            color: $sg-green;
            background: $sg-white;
            --indicator-color: $sg-green;
            --indicator-color: $sg-white;
            --color-checked: $sg-green;

            label {
                color: $sg-black;
            }
        }
        

        &::before {
            display: none;
        }
    }

    .segment-button-indicator {
        display: none;
    }
}

.social-media-links {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 $sg-padding;
    margin-top: calc(2 * $sg-padding);
    column-gap: calc(2 * $sg-padding);
    flex-shrink: 0;

    &__item {
        background-size: contain;
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;
    }
}

.settings-software-information {
    color: white;
    font-size: 12px;
    padding: 0 $sg-padding;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

  