@import 'mixins.scss';
@import 'footer.scss';
@import 'settings.scss';
@import 'header.scss';
@import 'filters.scss';
@import 'venue-list.scss';
@import 'venue.scss';
@import 'slider.scss';
@import 'modals.scss';
@import 'searchbar.scss';
@import 'generics.scss';
@import 'news.scss';
@import 'gallery.scss';
@import 'loading.scss';

* {
    font-family: $sg-font-family;
    visibility: visible !important;
}

ion-app {
    background-color: $sg-green;
}

@media only screen and (min-width: 960px) {
    .homeLogoContainer,
    .searchBar-container {
        max-width: 700px;
        margin: 0 auto;
    }

    .homeLogoContainer {
        background-size: 136px;
    }

    .searchBar-container {
        display: block !important;
        margin-top: 20px;
    }
}

.ion-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.slick-slide {
    background: inherit;
}

ion-page {
    height: 100vh;
    position: relative;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background-color: $sg-green;
}

.sg-body {
    &.home {
        height: calc(100dvh - 56px);
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        position: relative;
        display: block;
    }

    @media only screen and (min-width: 960px) {
        .page-container {
            max-width: 800px;
            margin:  auto;
            display: block;
        }

        &.home {
            .page-container {
                height: calc(100dvh - 112px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.logo-container {
    background-repeat: no-repeat;
    background-position: center 34px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: 100px;
    font-weight: 600;
    background-size: 100px;
    height: 200px;
    flex-shrink: 0;

    @media only screen and (max-height: 700px) {
        background-size: 15vh;

        .start-title {
            font-size: 18px;
        }
    }

    @media only screen and (max-height: 600px) {
        background-size: 17vh;
        background-position: center calc(2 * $sg-padding + 4px);

        .start-title {
            font-size: 18px;
        }
    }
}


.grid-container {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    grid-auto-rows: calc(50vw - 30px);
    grid-gap: $sg-padding;
    padding: 0 $sg-padding calc(2 * $sg-padding);
    height: calc(100dvh - 112px);
    overflow-y: auto;

    &.home {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        width: 254px;
        padding: 0;
        height: auto;
        margin: $sg-padding auto;

        @media only screen and (min-height: 700px) {
            margin-top: 50px;
            gap: 30px;
            width: 300px;
        }

        @media only screen and (min-width: 960px) {
            width: 100%;
            margin: 48px auto 0;
            display: flex;
            column-gap: 48px;
            justify-content: center;
        }
    }

    &.venue {
        @media only screen and (min-width: 960px) {
            margin: 0 auto;
            padding-top: calc(2 * $sg-padding);
            grid-template-columns: repeat(4, 180px);
        }
    }

    .news {
        grid-auto-rows: calc(50vw + 24px);
        margin-top: 0;

        @media only screen and (min-width: 960px) {
            grid-auto-rows: 230px;
        }
    }

    .kachel {
        font-weight: 600;

        &.main-category {
            background-repeat: no-repeat;
            position: relative;
            width: 120px;
            height: 120px;
            text-decoration: none;
            background-color: $sg-white;
            border-radius: 50%;
            background-size: 50%;
            background-position: center;
            display: flex;
            align-items: end;
            color: $sg-green;
            justify-content: center;
            font-size: 12px;
            background-position-y: 40%;
            padding-bottom: 20px;

            @media only screen and (min-height: 700px) {
                width: 135px;
                height: 135px;
            }

            @media only screen and (min-width: 960px) {
                width: 120px;
                height: 120px;
            }
        }

        &.child-category {
            margin: 0;
            // overflow: hidden;
            width: 100%;
            font-size: 12px;
            height: 100%;
            place-items: center;
            border-radius: $sg-border-radius-large;
            background-color: $sg-white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 45%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            text-decoration: none;
            padding-bottom: 13px;
            color: $sg-green;
            cursor: pointer;

            &.map {
                padding: 0;
                position: relative !important;

                iframe {
                    height: 100%;
                    width: 100%;
                }
            }

            &.hours {
                flex-direction: column;
                align-items: center;
                justify-content: $sg-padding;
            }

            &.gallery {
                padding: 0;
                position: relative;
            }
        }

        &-heading {
            margin: 0 0 16px;
            font-size: 14px;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            width: 100%;
            display: inline-grid;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    @media only screen and (min-width: 960px) {
        margin: 0 auto;
        padding-top: calc(2 * $sg-padding);
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 180px;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;  
        scrollbar-width: none;  
    }
}

.category {
    &-header {}
}

.sg-button {
    @include sg-button();
}

.start-title {
    margin-bottom: 10px;
}

.post-image {
    object-fit: cover;
    height: 160px;
    object-position: center;
    width: 100%;

    @media only screen and (min-width: 960px) {
        height: 300px;
        padding: 0 16px;
        border-radius: 3%;
        margin-bottom: calc(1.5 * $sg-padding);
    }
}