.sg-modal {
    .sc-ion-buttons-ios-h {
        background-color: transparent !important;
    }

    div {
        background-color: transparent;

        ion-title, .button, .sc-ion-buttons-ios-h, .toolbar-title {
            background-color: transparent !important;
        }
        
        ion-title {
            text-align: left;
            padding-left: 24px;
            font-weight: 600;
            font-size: 18px !important;
            text-transform: uppercase;

            &.filter {
                text-align: center;
                padding: initial;
            }
        }

        .sc-ion-buttons-md-h {
            color: $sg-green;
        }

        .close-modal-button {
            position: absolute;
            right: 0;
        }
    }

    &.hours {
        ion-list {
            * {
                background-color: transparent;
            }

            ion-item {
                label {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    padding: 10px 0;

                    span {
                        font-weight: 600;
                    }

                    p {
                        margin: 0;
                        color: $sg-grey;
                    }
                }
            }
        }
    }

    &.booking {
        * {
            background-color: transparent;

            &:not(ion-title) {
                font-size: 14px !important;
            }
        }

        div.number-people {
            width: 164px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: calc($sg-padding / 2);
        }

        #dateTimeSpan, .input-number-people, .input-name  {
            height: 32px;
            text-align: center;
            padding-left: 8px;
            padding-right: 8px;
            border: 0.5px solid #808080;
            border-radius: 8px;
            display: flex;
            align-items: center;
            width: 180px;
            justify-content: center;
            transform: scale(0.9);
            font-size: 16px !important;
        }

        .input-number-people, .input-name {
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .item-lines-default {
            margin-bottom: 10px;
        }

        label {
            width: 50%;
        }

        ion-input,
        ion-input label {
            height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: none;
        }


        .sg-modal-button {
            height: 12px;
            width: 12px;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            &.minus {
                background-image: url(../../public/icons/minus-green.svg);
            }

            &.plus {
                background-image: url(../../public/icons/add-green.svg);
            }
        }

        .input-number-people {
            width: 118px;
            text-align: center;
            margin: 0 10px;
        }

        input.native-input {
            height: 32px;
            text-align: center;
            border: 0.5px solid $sg-grey;
            border-radius: $sg-border-radius;
        }
    }

    &.categories,
    &.hours {
        ion-list {
            overflow-y: auto;
            padding-bottom: 24px !important;
        }
    }

    &.gallery {
        ion-toolbar {
            padding: 0;
            background-color: $sg-green;

            * {
                background-color: $sg-green !important;
                color: $sg-white !important;
            }

            ion-title {
                font-weight: 700;
                text-transform: uppercase;
                align-items: center;
                text-align: left;
                padding-left: 16px;
                display: flex;
                justify-content: flex-start;
            }
        }

        .slick-slider {
            .slick-slide {
                background-color: $sg-black;

                >div {
                    background-color: $sg-black;
                }

                .slide {
                    height: 350px;
                    margin-top: calc(50vh - 56px - 175px);

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            button {
                left: initial;
                right: initial;
                top: calc(50vh - 56px);

                &.slick-next {
                    right: 0;
                }
            }
        }

    }

    .venue-modal-category {
        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            background-color: transparent;

            img {
                background-color: transparent;
            }
        }

        &__label {
            margin: $sg-padding;
            margin-left: 0;
            background-color: transparent;

        }
    }
}

.toolbar-background,
.toolbar-container {
    background-color: $sg-green !important;
    background: none !important;
}

.datetime-popover {
    border-radius: 16px;
    z-index: 1000;

    .popover-viewport {
        background-color: transparent !important;
        position: relative;
    }

    ion-datetime {
        background-color: transparent !important;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
  
    ion-backdrop {
    backdrop-filter: blur(10px); // Blur background for better visual effect
    z-index: 999; // Ensure the backdrop is behind the popover
    }
}

.datetime-popover::part(content) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    max-width: 90vw;
    max-height: 90vh;
}

.sg-datetime {
    display: contents;
}
  
.popup__no-menu-online {
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(241, 241, 241, 0.8);
    color: $sg-black;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    font-size: 14px;
    text-align: center;
    margin-top: calc(4 * $sg-padding);
}
  
