* {
    background-color: transparent;
}


.sc-ion-searchbar-ios-h {
    height: 36px;
    min-height: 36px;
    border: 1px solid;
    padding: 0 !important;
    border: 1px solid $sg-grey;
    border-radius: $sg-border-radius;
    z-index: 101;
    background-color: $sg-white;
}

.searchbar-input-container {
    height: 36px;
    width: 100%;

    .searchbar {
        margin-bottom: -20px;

        &-input {
            color: black !important;
            background-color: $sg-white !important;
            --placeholder-color: $sg-grey;
            --icon-color: $sg-grey;
            border-radius: $sg-border-radius;
            border-color: transparent;
            width: 100%;
            height: 100%;
            padding-left: calc(2.5* $sg-padding) !important;

            &:focus-visible {
                border-color: transparent;
            }
        }

        &-search-icon {
            width: 18px;
            position: absolute;
            left: 10px;
            top: 8px;
            color: grey;
            background-color: transparent;
        }

        &-clear-button {
            position: absolute;
            right: 10px;
            top: 8px;
            background-color: transparent;
            color: $sg-grey;

            ion-icon {
                width: 16px;
                height: 16px;
                background-color: transparent;
            }
        }
    }
}



.search-list {
    max-width: 768px;
    margin: -7px 0;
    --background: $sg-white;
    background-color: $sg-white;
    --ion-background-color: $sg-white;
    --ion-text-color: $sg-grey;
    position: fixed;
    z-index: 100;
    width: calc(100vw - 32px);
    padding-top: 14px;
    border-bottom-right-radius: $sg-border-radius;
    border-bottom-left-radius: $sg-border-radius;
    border: 1px solid $sg-grey;

    * {
        background-color: $sg-white;
    }
}

ion-thumbnail {
    width: 24px;
    /* Set the desired width (half of the original size) */
    height: 24px;
    /* Set the desired height (half of the original size) */
}

ion-label {
    --color: initial;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    a {
        color: var(--sg-grey);
        width: 100%;
        display: block;
        text-decoration: none;
        font-family: $sg-font-family;
        font-size: 14px;
    }
}

.searchbar-container, {
    margin: 20px 0;
    height: auto;
    display: inline-table;
    width: 100%;
    background-color: transparent;
    padding: 0 16px;
}

.search-results {
    background-color: transparent;
    height: calc(100dvh - 164px);
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: block;

    * {
        background-color: transparent;
    }
}

.search-results-heading {
    font-size: 16px;
    color: $sg-white;
    margin: 0 0 8px;

    &.post {
        padding-top: $sg-padding;
    }
}

.search-results-item {
    background-color: $sg-white;
    display: flex;
    margin: 0 0 10px;
    width: 100%;
    border-radius: $sg-border-radius-large;
    height: 55px;
    color: $sg-black;
    justify-content: flex-start;
    align-items: center;
    padding-left: $sg-padding;
    text-decoration: none;

    &-category {
        background-size: 24px;
        background-repeat: no-repeat;
        padding-left: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        background-position-y: center;
    }
}

.map-geocoding {
    left: $sg-padding;
    right: $sg-padding;
    max-width: 768px;
    margin: auto;
    display: block;

    .search-button {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    form {
        max-width: initial !important;
        width: 100% !important;

        .input-group {
            color: black !important;
            background-color: $sg-white !important;
            border-radius: $sg-border-radius;
            border-color: transparent;
            min-height: 32px;
            width: 100%;
            height: 100%;
            padding-left: calc(2.5* $sg-padding) !important;

            &:focus-visible {
                border-color: transparent;
            }

            input {
                border-radius: $sg-border-radius;
            }
        }

        .clear-button-container {
            display: flex !important;
        }
    }
}