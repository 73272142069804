.sg-body.generic {
    height: calc(100dvh - 54px - $sg-padding);
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: block;

    @media only screen and (min-width: 960px) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .logo-container {
        margin-bottom: $sg-padding;
        padding-left: $sg-padding;
    }

    .start-title {
        text-align: left;

        @media only screen and (min-width: 960px) {
            margin-bottom: 0;
            flex-grow: 1;
        }

        &.not-found {
            text-align: center;
        }
    }

    .generic-page {
        padding: 0 16px;
        color: $sg-white;
        font-size: 14px;
        display: flex;
        flex-direction: column;

        &.center {
            text-align: center;
        }

        a {
            color: $sg-black;
            font-weight: 600;
        }

        @media only screen and (min-width: 960px) {
            &::-webkit-scrollbar {
                display: none;
            }
        
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
}