.venue-card {
    margin: 0;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    height: 100%;
    place-items: center;
    border-radius: $sg-border-radius-large;
    background-color: $sg-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 45%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-decoration: none;
    padding-bottom: 13px;
    color: $sg-green;
    cursor: pointer;

    &__heading {
        margin: 0 0 16px;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        display: inline-grid;
        grid-column-start: 1;
        grid-column-end: 3;
        background-color: transparent;
    }

    &__weekdays {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        row-gap: 5px;
        margin-top: calc(-0.5* $sg-padding);
        background-color: transparent;

        .weekday {
            width: 25%;
            height: 24px;
            color: $sg-green;
            display: flex;
            justify-content: center;
            background-color: transparent;

            div {
                border-radius: 50%;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10px;
                border: 1px solid $sg-green;
                background-color: transparent;
            }

            &.open div {
                background-color: $sg-green;
                color: $sg-white;
            }

            /* Adjust the number of columns for the second row */
            &:nth-child(n+5) {
                grid-column: span 3;
            }
        }
    }

    .restaurant-open-status {
        font-weight: 600;
        font-size: 12px;
        background-color: transparent;
        margin-top: $sg-padding;
    }

    &.map {
        padding: 0;
        position: relative !important;

        iframe {
            height: 100%;
            width: 100%;
        }

    }

    &.categories {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: $sg-padding-small;

        div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 16px;
            background-color: transparent;

            .category-item {
                height: 34px;
                width: 34px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-color: transparent;
                --background: transparent;
                text-decoration: none;
            }
        }
    }

    &.hours {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: $sg-padding-small;
        background-color: $sg-white;
    }

    &.gallery {
        background-color: transparent;
        padding: 0;
        position: relative;

        .gallery-empty {
            border: 1px solid $sg-white;
            background-color: $sg-green;
            width: 100%;
            height: 100%;
            background-size: 40px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: $sg-border-radius-large;
        }

        img {
            border-radius: $sg-border-radius-large;
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: brightness(70%) contrast(50%);
        }

        .photo-svg {
            enable-background: new 0 0 30 30;
            position: absolute;
            height: 100px;
            width: 40px;
            bottom: calc(50% - 50px);
        }
    }

    &.half {
        height: 55px;
        padding: 0 0 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        text-transform: uppercase;
        background-size: 24px;
        background-position-x: calc(100% - 20px);
        margin-bottom: $sg-padding;
        font-size: 12px;
        color: $sg-black;
        width: 100%;

        @media only screen and (min-width: 960px) {
            width: 50%;
        }

        .additional-information {
            background-color: transparent;
            font-weight: 500;
            margin-left: 5px;
            cursor: pointer;
            text-transform: none;
        }
    }
}

.venue-contact-container {
    display: flex;
    justify-content: center;
    column-gap: calc(2 * $sg-padding);
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
    left: 0;
    right: 0;
    bottom: 80px;
    position: fixed;
    background-color: transparent;

    @media only screen and (min-width: 960px) {
        bottom: 120px;
    }
}

.contact-link, .event-link {
    background-color: $sg-white;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-size: 25px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.5), 0 4px 6px -4px rgb(0 0 0 / 0.5);
}

.event-link {
    box-shadow: none;
    border-radius: initial;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    height: 64px;
    width: 64px;
    z-index: 10;
}

.venue-no-fotos {
    height: 100dvh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 $sg-padding-small;
    background-color: $sg-black;
    color: $sg-white;
}

.fullwidth-links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 0;
    padding-bottom: calc(6 * $sg-padding) !important;
    position: relative;

    @media only screen and (min-width: 960px) {
        margin: 40px auto 0;
        width: 768px;
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-end: 4;
        display: flex;
        flex-direction: row;
        column-gap: $sg-padding;
    }
}

:root {
    --ion-color-favorite: #69bb7b;
    --ion-color-favorite-rgb: 105, 187, 123;
    --ion-color-favorite-contrast: #ffffff;
    --ion-color-favorite-contrast-rgb: 255, 255, 255;
    --ion-color-favorite-shade: #5ca56c;
    --ion-color-favorite-tint: #78c288;
}

.booking-modal-line {
    display: flex;
    align-items: center;
    padding: calc($sg-padding / 3) $sg-padding;

    label {
        width: 50%;
    }

    &:first-child {
        margin-top: $sg-padding;
    }
}