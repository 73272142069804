/* Buttons, Links, Variables */

$sg-green: #698047;
$sg-black: #2d2d2d;

$body-background: $sg-green;
$sg-overlay-green: #506136;
$sg-white: #F1F1F1;
$sg-grey: #808080;
$font-color: $sg-white;
$sg-padding: 16px;
$sg-padding-small: 12px;
$sg-border-radius: 8px;
$sg-border-radius-large: 16px;
$sg-title-font-size: 20px;
$sg-box-shadow-default: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.19);
$sg-font-family: 'Montserrat';

@mixin sg-button() {
    background-color: $sg-white;
    border-radius: $sg-border-radius;
    margin: 24px auto;
    padding: $sg-padding;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: auto;
    display: flex;
    font-size: 14px;
    color: $sg-black;
    border: 1px solid $sg-green;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

:root {
    --ion-color-primary: #698047;
  }