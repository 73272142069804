.venue-list {
    width: 100%;
    overflow-y: auto;
    margin-top: calc(4 * $sg-padding);
    height: calc(100dvh - 56px);
    padding: 24px $sg-padding 200px;
    background-color: $sg-green;

    &.event {
        font-size: 16px;
        padding-top: 0;
        margin-top: calc(3 * $sg-padding);

        @media only screen and (min-width: 960px) {
            margin-top: calc(1 * $sg-padding);
        }


        h2 {
            font-size: 16px;
            margin-top: calc(2 * $sg-padding);
            text-align: left;
            color: $sg-white;
        }

        .venue-list__item-hours {
            margin-right: calc(2 * $sg-padding);
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &__item {
        background-color: #F1F1F1F1;
        border-radius: 17px;
        background-position: right;
        background-repeat: no-repeat;
        background-image: none;
        margin: 10px 0;
        min-height: 64px;
        max-height: 64px;
        padding: 0 20px;
        background-size: 24px;
        background-position-x: calc(100% - 20px);
        width: 100%;
        justify-content: flex-start;
        align-content: center;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        position: relative;

        &-link {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            z-index: 1;
        }

        &.closed {
            background-color: rgb(195, 195, 195);
        }

        &-map {
            position: absolute;
            left: var(--sg-padding);
            right: var(--sg-padding);
            width: auto;
            box-shadow: var(--sg-box-shadow-default);
            top: 100px;
        }

        &-name {
            color: $sg-black;
            font-size: 14px;
            display: inline-block;
            margin: 0;
            text-align: left;
            font-weight: 600;
        }

        &-hours {
            font-size: 12px;
            color: $sg-grey;
            width: 100%;
            text-align: left;
            margin: 0;
        }

        &.map {
            position: absolute;
            left: $sg-padding;
            right: $sg-padding;
            width: auto;
            box-shadow: $sg-box-shadow-default;
            top: 122px;

            @media only screen and (min-width: 960px) {
                top: 130px;
                max-width: 768px;
                position: absolute;
                left: calc(50% - 384px);
            }
        }

        &.ad {
            max-height: initial;
            height: 138px;
            position: relative;
            overflow: hidden;
            padding: 0;

            .ad-text {
                color: $sg-white;
                z-index: 2;
                padding: $sg-padding;
                font-weight: 600;
            }

            .ad-image {
                background-position-x: center;
                background-size: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                filter: brightness(.5) blur(1px);
                z-index: 1;
            }
        }
    }

    @media only screen and (min-width: 960px) {
        max-width: 800px;
        margin: 0 auto;
        padding-top: 0;
    }
}

.address-bar {
    align-items: center;
    width: 100%;
    display: flex;
    font-size: 13px;
    position: fixed;
    right: 0;
    width: 100vw;
    top: 86px;
    background-color: transparent;
    z-index: 1;

    &__icon {
        height: 14px;
        width: 14px;
        display: flex;
        position: absolute;
        top: 14px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;

        &.pin {
            background-image: url(../../public/icons/pin-black.svg);
            left: calc(2 * $sg-padding);
        }

        &.remove {
            background-image: url(../../public/icons/bin-black.svg);
            right: calc(2 * $sg-padding);
        }
    }
    
    input {
        background-color: $sg-white;
        color: black !important;
        background-color: $sg-white !important;
        --placeholder-color: $sg-grey;
        --icon-color: $sg-grey;
        border-radius: $sg-border-radius;
        border-color: transparent;
        width: 100%;
        height: 36px;
        margin: 2px $sg-padding calc(1 * $sg-padding);
        padding-left: calc(2.5* $sg-padding) !important;

        &:focus-visible {
            border-color: transparent;
        }
    }

    @media only screen and (min-width: 960px) {
        max-width: 800px;
        left: calc(50% + 256px);
        top: 120px;
    }
}


