.grid-container.news {
    grid-auto-rows: calc(50vw + 24px);
    margin-top: 0;
    height: calc(100dvh - 96px);

    @media only screen and (min-width: 960px) {
        grid-auto-rows: 230px;
        grid-column-gap: $sg-padding;
        grid-row-gap: calc(2 * $sg-padding);
    }
}

.start-title.news {
    background-image: url(../../public/icons/news-white.svg);
    height: 40px;
    background-size: 30px;
    align-items: center;
    display: flex;
    background-position: center right;
}

.post-card {
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: normal;
    flex-direction: column;

    img {
        border-radius: 17px;
        margin-bottom: 10px;
        background-size: cover;
        height: calc(50vw - 30px);
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
        width: 100%;
    }

    .post-date {
        color: $sg-black;
        font-size: 12px;
    }

    .post-title {
        color: $sg-white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
    }

    @media only screen and (min-width: 960px) {
        height: 240px;

        img {
            height: 180px;
        }
    }
}

.post-page {
    .start-title {
        text-align: left;
        padding-top: 0;
        padding-bottom: 8px;
        font-size: $sg-title-font-size;
        margin-top: 0;
        font-size: 18px;
        margin-bottom: 0;

        @media only screen and (min-width: 960px) {
            flex-grow: 1;
            padding-bottom: 0;
        }
    }

    .post-date {
        display: block;
        padding-bottom: 2px;
        color: $sg-black;
        font-size: 14px;
    }

    .news-container {
        .news-content {
            padding-top: 0 !important;
            margin-top: -8px;
            font-size: 14px;
            color: $sg-white;

            figure, img {
                margin: 0;
                max-height: 200px;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
    
            a,
            strong {
                color: $sg-black;
                font-weight: 600;
    
            }
        }
    }
}

.sg-body.post-page {
    height: calc(100dvh - 54px);
    display: block;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: calc(2 * $sg-padding);
}

.search-results-item {
    &.news-link {
        height: 55px;
        margin: 0;
    }
}
