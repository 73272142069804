.filter-map-container {
    display: flex;
    height: 32px;
    gap: 10px;
}

.sg-filter {
    cursor: pointer;
    z-index: 100;
    height: 32px;
    width: 36px;
    background-size: 70%;
    position: relative;
    background-image: url(../../public/icons/filter_ionic.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $sg-border-radius;
    border: 1px solid $sg-white;

    span {
        background-color: red;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        font-size: 9px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -7px;
        color: $sg-white;
        top: -8px;
    }
}

ion-header.transparent {
    .sg-filter {
        background-color: rgba(211, 211, 211, 0.8);
    }

    .arrow-back {
        background-image: url(../../public/icons/back_arrow_green.svg) !important;
    }
}

.chevronDown {
    background-image: url(../../public/icons/down-white.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    height: 10px;
    width: 10px;
}

span.sortedBy {
    align-items: center;
    width: 100%;
    display: flex;
    gap: 4px;
    font-size: 13px;
    position: sticky;
    background-color: $sg-green;
    margin-top: 0;
    left: 0;
    top: 90px;
    padding: calc(0.5 * $sg-padding) $sg-padding calc(0.5 * $sg-padding);
    color: $sg-white;
    z-index: 3;

    &.event {
        top: 70px !important;
        padding: 10px 0px 10px !important;
    }

    @media only screen and (min-width: 960px) {
        width: 800px;
        margin: 0 auto;
        z-index: 1000;
        margin-top: 110px;
        cursor: pointer;

        &.event {
            margin-top: 50px;
        }
    }

}

ion-segment-button {
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Custom styling for the active segment button */
ion-segment-button.ion-activated {
    background-color: var(--ion-color-primary);
    color: white;
    position: relative;
}

/* Animation for sliding effect */
ion-segment-button::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--ion-color-primary);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
}

ion-segment-button.ion-activated::before {
    transform: scaleX(1);
}

ion-modal.modal-gallery {
    --height: 100%;
    --background: $sg-black;
}

ion-modal.modal-gallery .ion-page {
    justify-content: center;
}

ion-modal.modal-gallery ion-toolbar {
    position: fixed;
    top: 0;
    --background: $sg-black;
}

.item-inner {
    border-style: none;
}

.filter-header {
    font-weight: 600;
    text-align: center;
    width: 100%;
    display: block;
    padding: 20px 0 10px;
    color: $sg-black;
    border-bottom: 0.1px solid $sg-grey;
    background-color: $sg-white;
    font-size: $sg-title-font-size;
}

.filter-group {
    background-color: $sg-white;
    height: 100%;

    .sorting-item {
        display: flex;
        justify-content: flex-start;
        column-gap: $sg-padding;
        align-items: center;
    }
}

.filter-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
    padding: 10px 0;
    background: none;

    &__card {
        background: none;
        height: calc(3 * $sg-padding);
        border-radius: $sg-border-radius;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $sg-black;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 15px -3px rgba(0, 0, 0, 0.19);
        background-position: 8px center;
        background-repeat: no-repeat;
        background-size: 14px;
        padding-left: 27px;
        padding-right: 5px;
        font-size: 12px;
        height: 34px;

        &.true {
            background-color: $sg-green;
            color: $sg-white;
        }
    }
}

#radioSorting {
    display: flex;
    flex-direction: column;
    row-gap: $sg-padding;
    padding-top: $sg-padding;

    .radio-wrapper {
        flex-direction: row-reverse;
    }


    ion-radio {
        &::part(container) {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 2px solid #ddd;
        }

        label {
            position: absolute;
            left: 50px;
            top: 0;
        }

        &.radio-checked {
            &::part(mark) {
                width: 6px;
                height: 10px;
                border-width: 0 2px 2px 0;
                border-style: solid;
                border-color: #fff;
                transform: rotate(45deg);
            }

            &::part(container) {
                background-color: $sg-green;
                border-color: transparent;
            }
        }
    }
}

.modal-description-text {
    background-color: transparent;
    color: $sg-black;
}

.picker-highlight {
    background-color: transparent;
}

.date-time-circle {
    position: fixed;
    bottom: 85px;
    display: flex;
    justify-content: center;
    z-index: 2;
    width: 100px;
    height: 100px;
    margin-left: calc(50% - 50px);
    background-color: transparent;

    * {
        background-color: transparent;
    }

    @media only screen and (min-width: 960px) {
        margin-left: 350px;
    }
}

.date-spans {
    position: absolute;
    top: 24px;
    height: 90px;
    display: flex;
    color: $sg-black;
    flex-direction: column;
    border-radius: 50%;
    width: 90px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.5), 0 4px 6px -4px rgb(0 0 0 / 0.5);
    opacity: 0.8;
    background-color: lightgrey;
}

.date-spans {
    &.active {
        border: 4px solid #8db255;
        background-color: $sg-white;
        opacity: 1;
    }

    .date {
        font-size: 11px;
    }

    .time {
        font-size: 24px;
    }
}
