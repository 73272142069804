.footer-background {
    display: none;
}

.footer {
    background-color: $sg-white;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    bottom: 0;
    padding: $sg-padding;

    &-inner {
        height: 100%;
        width: 100%;
        display: flex;
        background: none;
        justify-content: space-around;
        max-width: 600px;
    }

    a {
        text-decoration: none;
        color: $sg-black;
        position: relative;
        z-index: 199;
        height: 24px;
        width: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        font-size: 14px;

        @media only screen and (min-width: 960px) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 36px;
            background-position-x: left;
            font-weight: 600;
            width: auto;

            span {
                background-color: transparent;
            }
        }
    }

    &-link-name {
        @media only screen and (max-width: 1199px) {
            display: none;
        }
    }
}

.info-toast{
    transform: translateY(-70px) !important;
}