.slider-container {
    height: 100dvh;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;

    @media only screen and (min-width: 960px) {
        left: 0;
        right: 0;
    }
}

.slick-slider,
.slick-list,
.slick-track {
    height: 100%;
    /* Slick Slider Elemente nehmen die gesamte Höhe ein */
}

.slick-slide {
    display: flex;
    flex-direction: column;
    /* Flexbox für vertikale Anordnung */
    align-items: center;
    justify-content: flex-start;
    /* Starten Sie den Inhalt am Anfang der Slide */
    height: 100%;
    /* Slide nimmt die volle Höhe des Viewports ein */
    background: #ccc;
}

.scrollable-list {
    width: 100%;
    flex-grow: 1;
    /* Liste wächst, um den verfügbaren Platz zu füllen */
    /* Ermöglicht vertikales Scrollen */
}

.slick-prev,
.slick-next {
    z-index: 1;
}

.childCategoryCard.map {
    padding: 0;
    position: relative !important;
}

.childCategoryCard.map iframe {
    height: 100%;
    width: 100%;
}

.map-wrap {
    position: relative;
    width: 100%;
    height: 100dvh;
    /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}

.map-geocoding{
    position: absolute;
    top: 90px;
    left: 18px;
}

.single-restaurant.map {
    position: absolute;
    left: var(--sg-padding);
    right: var(--sg-padding);
    width: auto;
    box-shadow: var(--sg-box-shadow-default);
    top: 100px;
}

.maplibregl-marker {
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s;
    will-change: transform;
    background-color: transparent;
}

.maplibregl-canvas {
    left: 0;
    position: absolute;
    top: 0;
}

.maplibregl-ctrl-bottom-right {
    padding-bottom: 60px;
    background-color: transparent;
}

.maplibregl-ctrl-icon {
    background-color: $sg-white;
}

.slide {
    position: relative;
}