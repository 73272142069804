.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    flex-direction: column;
    position: relative;
}

.logo-spinner-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container1 {
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    z-index: 1;  // Logo bleibt über dem Spinner
}

.spinner {
    position: absolute;
    width: 140px; // 20px größer auf jeder Seite als das Logo
    height: 140px;
    color: $sg-white;
    z-index: 0;  // Spinner bleibt hinter dem Logo
}

.loading-info {
    margin-top: 30px;
    font-size: 14px;
    color: $sg-white;
    text-align: center;
}

