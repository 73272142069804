.arrow-back {
    height: 24px;
    width: 24px;
    background-size: 200%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-right: 4px;
}

.generic .arrow-back, .news .arrow-back {
    position: absolute;
    left: $sg-padding;
    top: $sg-padding;

    @media only screen and (min-width: 960px) {
        position: relative;
        left: initial;
        top: initial;
    }
}

.page-container .header-container {
    @media only screen and (min-width: 960px) {
        display: flex;
        position: relative;
        justify-content: space-between;
        height: 40px;
        align-items: center;
    }
}

ion-header {
    z-index: 10;
    
    .header-container {
        display: flex;
        position: relative;
        justify-content: space-between;
        height: 40px;
        align-items: center;
    
        @media only screen and (min-width: 960px) {
            &.top-padding {
                margin-top: calc(2 * $sg-padding);
            }
        }

        &.gallery {
            .start-title, button {
                color: $sg-white;
                text-transform: uppercase;
            }
        }

        .share-icon {
            background-size: 20px;
            cursor: pointer;
            height: 20px;
            width: 20px;
            position: absolute;
            display: flex;
            right: 6px;
            background-image: url(../../public/icons/share-white.svg);

        }

        .share-icon.info {
            margin-top: 2px;
            right: initial;
            background-image: url(../../public/icons/information-white.svg);
            padding: 2px 16px;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
        }

        &__title {
            display: flex;
            justify-content: flex-start;
            flex-grow: 1;
        }

        &.news {
            padding: 0 16px;
        }

        &.venue {
            height: auto;

            .start-title {
                height: 40px;
                display: flex;
                align-items: center;
                flex-grow: 1;
            }

            .share-icon {
                position: relative;
            }
        }
    }

    &.transparent {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);

        @media only screen and (min-width: 960px) {
            background: initial;
        }

        #segmentListMap {
            background-color: rgba(211,211,211,0.8);
        } 

        * {
            background-color: transparent;
        }

        .segment-button {
            background-color: rgba(211,211,211,0.8);

            label {
                color: $sg-black;
            }

            &-checked {
                background-color: $sg-white;
                opacity: 1;
            }
        }

        ion-segment {
            border-radius: $sg-border-radius;
            box-shadow: $sg-box-shadow-default;
        }

        .start-title {
            color: $sg-green;
            text-shadow: 0 0 3px #FFF;
        }

        .share-icon {
            background-image: url(../../public/icons/share-green.svg);

            &.info {
                background-image: url(../../public/icons/information-green.svg);
            }
        }

        #open-filter-modal {
            background-image: url(../../public/icons/filter_ionic-green.svg);
            background-color: transparent;
            box-shadow: $sg-box-shadow-default;
            backdrop-filter: blur(50px);
            /* Adjust the value for more or less blur */
            -webkit-backdrop-filter: blur(50px);

            ion-segment {
                border-radius: $sg-border-radius;
                box-shadow: $sg-box-shadow-default;
            }
        }
    }
}

.start-title {
    font-weight: 700;
    font-size: $sg-title-font-size;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    color: $sg-white;
    background-position-x: right;
    background-repeat: no-repeat;
    width: 330px;

    &.left {
        text-align: left;
        width: auto;
        margin-bottom: 0 !important;

        &.parent {
            background-size: 40px;
            line-height: 40px;
            flex-grow: 1;
        }

        &.venue {
            text-transform: uppercase;
            padding-left: 0;
            margin: 8px 0;
        }
    }

    &.padding {
        margin-bottom: calc(-1 * var(--sg-padding));
    }
}