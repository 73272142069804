.sg-body {
    &.gallery {
        display: flex;
        flex-direction: column;

        .gallery-container {
            height: calc(100dvh - 112px);

            @media only screen and (min-width: 960px) {
                height: calc(100dvh - 200px);
            }

            .slick-slide div {
                display: flex;
                align-items: center;
                background-color: $sg-black;
                height: 100%;

                .slide {
                    background-color: $sg-black;
                    height: 70%;
                    align-content: center;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 960px) {
                    background-color: transparent;

                    .slide {
                        height: 100%;
                        background-color: $sg-green;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .slick-arrow {
            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }
        }

        .slick-dots {
            li {
                button:before {
                    @media only screen and (min-width: 960px) {
                        font-size: 10px;
                    }
                }
                
                &.slick-active button:before {
                    opacity: .75;
                    color: $sg-white;
                }
            }
        
        }
    }
}